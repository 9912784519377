<template>
  <div>
    <div>
      <div
        class="top_bar bg_app_primary flex_x_betwwen"
        :class="{ topBarResize: mobileMenu }"
      >
        <div class="flex_x_betwwen landLordTenant-holder">
          <div class="pl-2 pb-2">
            <img
              @click="toggleMenuOut()"
              :class="{ hamburgerDisplay: !mobileMenu }"
              src="../assets/images/blue-hamburger.svg"
              class="hamburger bugermobile"
              alt=""
            />
          </div>
          <!-- :selected="who_you === 'Landlord'" -->
          <div class="landLordTenant-toggle">
            <select
              id="inputState"
              v-model="who_you"
              class="form-control landlordTenant-select"
              @change="logger()"
            >
              <option
                :selected="who_you"
                v-for="(item, index) in who"
                v-bind:value="item.value"
                :key="index"
              >
                {{ item.value }}
              </option>
              <!-- <option :selected="who_you === 'Tenant'">
              Tenant
            </option> -->
            </select>
          </div>
        </div>

        <div class="user d-flex align-items-center">
          <!-- <span class="verified" v-if="verified && subscribed"> -->
          <span class="verified" v-if="subscribed">
            <div>
              <img
                src="../assets/images/verify.svg"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Verified"
                alt="verified"
              />
            </div>
            <!-- <span class="d-none d-sm-none d-md-block">Verified</span> -->
          </span>
          <!-- <span class="notverified" v-if="!verified && subscribed">
            <img
              src="../assets/images/notverified.svg"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Not verified"
              alt=""
            />
            <span class="d-none d-sm-none d-md-block">Not Verified</span>
          </span> -->
          <!-- <span class="subscribed" v-if="!subscribed">
            <img src="../assets/images/verify.svg" alt="" />
            <span class="d-none d-sm-none d-md-block">SUBSCRIBE</span>
          </span> -->
          <span
            class="notsubscribe"
            v-if="!subscribed"
            @click="membership()"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Subscribe"
          >
            <span class="d-none d-sm-none d-md-block">SUBSCRIBE</span>
            <img
              @click="membership()"
              src="../assets/images/sub-iconhand.svg"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Subscribe"
              alt="subscribe"
            />
          </span>
          <span class="icon_sm whatsAppIcon">
            <a href="https://api.whatsapp.com/send?phone=2348061796909">
              <img
                class=""
                src="../assets/images/whatsapp-icon.svg"
                alt="whatsApp"
              />
            </a>
            <!-- <img class="rotate" src="../assets/images/settings.svg" alt="" /> -->
          </span>
          <span
            @click="showPopup = !showPopup"
            ref="notifi"
            class="icon_sm notificationHolder toggle-btn"
          >
            <img src="../assets/images/notification.svg" alt="" />
            <span class="notificationCount">0</span>
            <!-- <div v-show="showPopup" class="col-md-12">
            <div class="notification-msg">
              <p></p>
              <div class="noti-body">
                <div class="notification-body">

                  <div class="notifications-text">
                    <p>New subscription updates</p>
                    <p>
                      Your subscription has been succesfully renewed. To view
                      more details of of the plan ...
                    </p>
                  </div>
                </div>
                <div class="notification-body">

                  <div class="notifications-text">
                    <p>New subscription updates</p>
                    <p>
                      Your subscription has been succesfully renewed. To view
                      more details of of the plan ...
                    </p>
                  </div>
                </div>
                <div class="notification-body">
                  <div class="notifications-text">
                    <p>New subscription updates</p>
                    <p>
                      Your subscription has been succesfully renewed. To view
                      more details of of the plan ...
                    </p>
                  </div>
                </div>
                <div class="notification-body">

                  <div class="notifications-text">
                    <p>New subscription updates</p>
                    <p>
                      Your subscription has been succesfully renewed. To view
                      more details of of the plan ...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          </span>
          <div class="btn-group">
            <button
              class="dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="avatar-span">
                <img
                  class="avatar"
                  v-if="user.passportfile != null"
                  v-bind:src="user.passportfile"
                  alt=""
                />
                <img
                  class="avatar"
                  v-else
                  src="../assets/images/avatar.svg"
                  alt=""
                />
              </span>
            </button>
            <div class="dropdown-menu dropdown-menu-right nav-dropdown">
              <!-- <button
              @click="dropdownMoveTo('1')"
              class="drop-option dropdown-item"
              type="button"
            >
              <span class="top-dropdown"
                ><img src="../assets/images/user.svg" alt=""
              /></span>

              Quick Profile
            </button> -->
              <button
                v-if="userAccount === 'individual'"
                @mouseover="mouseOver"
                @click="dropdownMoveTo('2')"
                v-show="!activeIcon"
                class="drop-option dropdown-item"
                type="button"
              >
                <span class="top-dropdown"
                  ><img src="../assets/images/biodata-white.svg" alt=""
                /></span>

                Biodata
              </button>
              <button
                v-else
                @mouseover="mouseOver"
                @click="dropdownMoveTo('2')"
                v-show="!activeIcon"
                class="drop-option dropdown-item"
                type="button"
              >
                <span class="top-dropdown"
                  ><img src="../assets/images/biodata-white.svg" alt=""
                /></span>

                Profile
              </button>
              <button
                v-if="userAccount === 'individual'"
                @mouseleave="mouseOut"
                @click="dropdownMoveTo('2')"
                v-show="activeIcon"
                class="drop-option dropdown-item"
                type="button"
              >
                <span class="top-dropdown"
                  ><img src="../assets/images/biodata.svg" alt=""
                /></span>
                Biodata
              </button>
              <button
                v-else
                @mouseleave="mouseOut"
                @click="dropdownMoveTo('2')"
                v-show="activeIcon"
                class="drop-option dropdown-item"
                type="button"
              >
                <span class="top-dropdown"
                  ><img src="../assets/images/biodata.svg" alt=""
                /></span>
                Profile
              </button>
              <!-- <button
                @mouseover="mouseOver2"
                @click="dropdownMoveTo('3')"
                v-show="!activeIcon"
                class="drop-option dropdown-item"
                type="button"
              >
                <span class="top-dropdown"
                  ><img src="../assets/images/support.svg" alt=""
                /></span>
                Support
              </button> -->
              <!-- <button
                @mouseleave="mouseOut2"
                @click="dropdownMoveTo('3')"
                v-show="activeIcon"
                class="drop-option dropdown-item"
                type="button"
              >
                <span class="top-dropdown"
                  ><img src="../assets/images/support-black.svg" alt=""
                /></span>
                Support
              </button> -->
              <button
                @click="dropdownMoveTo('4')"
                class="drop-option dropdown-item"
                type="button"
                v-if="who_you === 'Landlord'"
              >
                <span class="top-dropdown"
                  ><img src="../assets/images/museum.svg" alt=""
                /></span>
                Add property
              </button>
              <!-- <button
                @click="dropdownMoveTo('5')"
                class="drop-option dropdown-item"
                type="button"
              >
                <span class="top-dropdown"
                  ><img
                    class="rotate"
                    src="../assets/images/white-settings.svg"
                    alt="settings"
                  />
                </span>
                Settings
              </button> -->
              <button
                @click="dropdownMoveTo('5')"
                class="drop-option dropdown-item"
                type="button"
              >
                <span class="top-dropdown"
                  ><img src="../assets/images/logout.svg" alt=""
                /></span>
                Logout
              </button>
            </div>
          </div>
          <!-- <img class="avatar profile ml-1" alt="" /> -->
          <!-- <img
            @click="toggleMenu()"
            :class="{ hamburgerDisplay: mobileMenu }"
            src="../assets/images/blue-hamburger.svg"
            class="hamburger"
            alt=""
          /> -->
          <button
            class="ml-3 mr-3 menu_trigger flex_center desktopBurger"
            @click="toggleMenu()"
          >
            <img
              v-if="mobileMenu === false"
              src="../assets/images/blue-hamburger.svg"
              class="hamburger"
              alt=""
            />
            <img
              style="width: 20px"
              v-else
              src="../assets/images/close-icon.svg"
              class="hamburger"
              alt=""
            />
          </button>

          <!-- <button>
          Profile
        </button> -->

          <!-- <i class="fas fa-bars cursor mobile text-white ml-3"></i> -->
        </div>
      </div>
      <div class="main bg_app_outlet">
        <div
          id="moveOutSlowly"
          class="sidebar"
          :class="{ motionOut: mobileMenu }"
        >
          <div
            class="brand d-flex align-items-center"
            :class="{ brandHolder: mobileMenu }"
          >
            <img
              style="cursor: pointer"
              :class="{ logoSize: mobileMenu }"
              @click="backtohome()"
              src="../assets/images/logo1.svg"
              class="logo_sm radius_5"
              alt=""
            />
            <img
              v-if="!mobileMenu"
              @click="toggleMenu()"
              :class="{ hamburgerDisplay: mobileMenu }"
              src="../assets/images/hamburger.svg"
              class="hamburger bugermobile"
              alt=""
            />
            <!-- <img
            v-else
            @click="toggleMenuOut()"
            :class="{ hamburgerDisplay: mobileMenu }"
            src="../assets/images/blue-hamburger.svg"
            class="hamburger"
            alt=""
          /> -->
          </div>
          <p class="underline"></p>
          <div class="links" :class="{ icon_links: mobileMenu }">
            <!-- <li class="text-right w-100 mobile">
            <i class="fas fa-bars text_app_tertiary cursor"></i>
          </li> -->

            <!-- <div v-for="(data, index) in routerLinks" :key="index">
          {{ data.icon }}
            <router-link
            class="router-link"
            :to="data.to"
            
          >
            <li
              class="d-flex align-items-center hover-list"
              :class="{ listPadding: mobileMenu }"
              @click="toggleMenu2()"
            >
              <span class="side-icon"
                ><img :src="data.icon" :alt="data.faci"
              /></span>
              <span class="spanHover" :class="{ listDisplay: mobileMenu }"
                >{{ data.name }}</span
              >
            </li>
          </router-link>
          </div> -->

            <router-link
              class="router-link"
              to="/dashboard/landlord"
              v-if="who_you === 'Landlord'"
            >
              <!-- Active link class to be used when ready dashboardActive -->
              <!-- :class="[
                    rent.rentfinance === 'YES' ? 'hasRentFinancing' : '',
                  ]" -->
              <div>
                <li
                  class="d-flex align-items-center hover-list"
                  :class="{ listPadding: mobileMenu }"
                  @click="toggleMenu2()"
                >
                  <span class="side-icon"
                    ><img src="../assets/images/database.svg" alt=""
                  /></span>
                  <span class="spanHover" :class="{ listDisplay: mobileMenu }"
                    >Dashboard</span
                  >
                </li>
              </div>
            </router-link>

            <router-link
              class="router-link"
              to="/dashboard/tenant"
              v-if="who_you === 'Tenant'"
            >
              <!-- Active link class to be used when ready dashboardActive -->
              <li
                class="d-flex align-items-center hover-list"
                :class="{ listPadding: mobileMenu }"
                @click="toggleMenu2()"
              >
                <span class="side-icon"
                  ><img src="../assets/images/database.svg" alt=""
                /></span>
                <span class="spanHover" :class="{ listDisplay: mobileMenu }"
                  >Dashboard</span
                >
              </li>
            </router-link>
            <router-link
              ref="/dashboard/safe"
              :class="{ dashboard_active: showActive }"
              class="router-link"
              to="/dashboard/safe"
            >
              <!-- :class="[
                  currentPath == '/dashboard/safe' ? 'dashboard_active' : '',
                ]" -->
              <div>
                <li
                  id="checkHover"
                  class="d-flex align-items-center hover-list"
                  :class="{ listPadding: mobileMenu }"
                  @click="toggleMenu2()"
                >
                  <span class="side-icon"
                    ><img src="../assets/images/safe.svg" alt="safe"
                  /></span>

                  <span class="spanHover" :class="{ listDisplay: mobileMenu }">
                    SAFE</span
                  >
                </li>
              </div>
            </router-link>

            <div >
              <!-- :class="[
                  currentPath == '/dashboard/safe' ? 'dashboard_active' : '',
                ]" -->
              <a :class="{ dashboard_active: showActive }" class="router-link">
                <li
                  id="checkHover"
                  class="d-flex align-items-center hover-list"
                  :class="{ listPadding: mobileMenu }"
                  @click="
                    toggleMenu2();
                    investRouteTo();
                    toggleCoop();
                  "
                >
                  <span class="side-icon"
                    ><img
                      src="../assets/icons/cooporative-icon.svg"
                      alt="cooporative"
                  /></span>

                  <span class="spanHover" :class="{ listDisplay: mobileMenu }">
                    Cooporative</span
                  >
                </li>
              </a>
            </div>
            <div class="d-none">
              <li
                id="checkHover"
                class="d-flex align-items-center hover-list"
                ref="coop"
                :class="{ listPadding: mobileMenu }"
                @click="toggleCoop()"
              >
                <span class="side-icon"
                  ><img src="../assets/icons/cooporative-icon.svg" alt="invext"
                /></span>

                <span class="spanHover" :class="{ listDisplay: mobileMenu }">
                  Cooporative</span
                >
                <span class="m-auto"
                  ><i
                    class=""
                    :class="
                      toggleCooporative
                        ? 'fa fa-chevron-up'
                        : 'fa fa-chevron-down'
                    "
                  ></i
                ></span>
                <!-- <small>Invest</small> -->
              </li>
              <div class="menudrop" v-if="toggleCooporative">
                <h6
                  class="pl-0 mb-3"
                  @click="
                    moveToInvestmentDashboard();
                    toggleCoop();
                    toggleMenu2();
                  "
                >
                  Dashboard
                </h6>
                <h6
                  class="pl-0 mb-3"
                  @click="
                    investRouteTo();
                    toggleCoop();
                    toggleMenu2();
                  "
                >
                  Invest
                </h6>
              </div>
            </div>

            <router-link
              ref="/dashboard/ovadraft"
              :class="{ dashboard_active: showActive }"
              class="router-link"
              to="/dashboard/ovadraft"
            >
              <!-- :class="[
                  currentPath == '/dashboard/safe' ? 'dashboard_active' : '',
                ]" -->
              <div>
                <li
                  id="checkHover"
                  class="d-flex align-items-center hover-list"
                  :class="{ listPadding: mobileMenu }"
                  @click="toggleMenu2()"
                >
                  <span class="side-icon"
                    ><img
                      style="width: 90%;"
                      src="../assets/icons/overdraft-icon.svg"
                      alt="overdraft"
                  /></span>

                  <span class="spanHover" :class="{ listDisplay: mobileMenu }">
                    Ovadraft</span
                  >
                </li>
              </div>
            </router-link>

            <router-link
              class="router-link"
              to="/dashboard/rent/pin"
              v-if="who_you === 'Tenant'"
            >
              <li
                class="d-flex align-items-center hover-list"
                :class="{ listPadding: mobileMenu }"
                @click="toggleMenu2()"
              >
                <span class="side-icon"
                  ><img
                    class="pl-1"
                    src="../assets/images/rentedicon.svg"
                    alt=""
                /></span>
                <span class="spanHover" :class="{ listDisplay: mobileMenu }"
                  >Rent Application</span
                >
              </li>
            </router-link>
            <router-link
              class="router-link"
              to="/addpropertyforsale"
              v-if="who_you === 'Landlord'"
            >
              <li
                id="checkHover"
                class="d-flex align-items-center hover-list"
                :class="{ listPadding: mobileMenu }"
                @click="toggleMenu2()"
              >
                <span class="side-icon"
                  ><img src="../assets/images/addplus.svg" alt=""
                /></span>

                <span class="spanHover" :class="{ listDisplay: mobileMenu }">
                  Add Property (Sale)</span
                >
              </li>
            </router-link>
            <router-link
              class="router-link"
              to="/dashboard/addpropertytenant"
              v-if="who_you === 'Tenant'"
            >
              <li
                data-toggle="tooltip"
                data-placement="top"
                title="For Rent Financing"
                id="checkHover"
                class="d-flex align-items-center hover-list"
                :class="{ listPadding: mobileMenu }"
                @click="toggleMenu2()"
              >
                <span class="side-icon"
                  ><img src="../assets/images/addplus.svg" alt=""
                /></span>

                <span class="spanHover" :class="{ listDisplay: mobileMenu }">
                  Rent Financing</span
                >
              </li>
            </router-link>
            <router-link
              class="router-link"
              to="/addproperty"
              v-if="who_you === 'Landlord'"
            >
              <li
                id="checkHover"
                class="d-flex align-items-center hover-list"
                :class="{ listPadding: mobileMenu }"
                @click="toggleMenu2()"
              >
                <span class="side-icon"
                  ><img src="../assets/images/addplus.svg" alt=""
                /></span>

                <span class="spanHover" :class="{ listDisplay: mobileMenu }">
                  Add Property (Rent)</span
                >
              </li>
            </router-link>
            <router-link
              class="router-link"
              to="/dashboard/landlordproperties"
              v-if="who_you === 'Landlord'"
            >
              <li
                class="d-flex align-items-center hover-list"
                :class="{ listPadding: mobileMenu }"
              >
                <span class="side-icon"
                  ><img src="../assets/images/museum.svg" alt=""
                /></span>
                <span class="spanHover" :class="{ listDisplay: mobileMenu }"
                  >My Properties</span
                >
                <span
                  class="propertyCount"
                  :class="{ listDisplay: mobileMenu }"
                  v-if="propertyNumber"
                  >{{ propertyNumber }}</span
                >
              </li>
            </router-link>

            <router-link class="router-link" to="/dashboard/rented">
              <li
                class="d-flex align-items-center hover-list"
                :class="{ listPadding: mobileMenu }"
                @click="toggleMenu2()"
                v-if="who_you === 'Tenant'"
              >
                <span class="side-icon"
                  ><img src="../assets/images/museum.svg" alt=""
                /></span>
                <span class="spanHover" :class="{ listDisplay: mobileMenu }"
                  >Rented</span
                >
                <span
                  class="propertyCount"
                  :class="{ listDisplay: mobileMenu }"
                  v-if="rentedpropertyNumber"
                  >{{ rentedpropertyNumber }}</span
                >
              </li>
            </router-link>

            <!-- <router-link class="router-link" to="/dashboard/facilities">
              <li
                id="checkHover"
                class="d-flex align-items-center hover-list"
                :class="{ listPadding: mobileMenu }"
                @click="toggleMenu2()"
              >
                <span class="side-icon"
                  ><img src="../assets/images/box.svg" alt=""
                /></span>

                <span class="spanHover" :class="{ listDisplay: mobileMenu }">
                  Facilities</span
                >
              </li>
            </router-link> -->
            <!-- <router-link>
              <li
                class="d-flex align-items-center hover-list"
                :class="{ listPadding: mobileMenu }"
              >
                <span class="side-icon"
                  ><img src="../assets/images/facility-icon.svg" alt=""
                /></span>
                <span class="spanHover" :class="{ listDisplay: mobileMenu }"
                  >Reports</span
                >
              </li>
            </router-link> -->
            <router-link
              ref="/dashboard/e-wallet"
              :class="{ dashboard_active: showActive }"
              class="router-link d-none"
              to="/dashboard/e-wallet"
            >
              <li
                id="checkHover"
                class="d-flex align-items-center hover-list"
                :class="{ listPadding: mobileMenu }"
                @click="toggleMenu2()"
              >
                <span class="side-icon"
                  ><img
                    src="../assets/images/wallet.svg"
                    style="width: 25px"
                    alt="wallet"
                /></span>

                <span class="spanHover" :class="{ listDisplay: mobileMenu }">
                  e-Wallet</span
                >
              </li>
            </router-link>
            <!-- <router-link
            class="router-link"
            to="//dashboard/propertyagreement"
            v-if="who_you === 'Landlord'"
          >
            <li
              class="d-flex align-items-center hover-list"
              :class="{ listPadding: mobileMenu }"
            >

              <span class="side-icon"
                ><img src="../assets/images/policyicon.svg" alt=""
              /></span>
              <span class="spanHover" :class="{ listDisplay: mobileMenu }"
                >PM Agreement</span
              >
            </li>
          </router-link> -->
            <router-link class="router-link" to="">
              <li
                id="checkHover"
                class="d-flex align-items-center hover-list"
                :class="{ listPadding: mobileMenu }"
                @click="
                  toggleMenu2();
                  membership();
                "
              >
                <span class="side-icon"
                  ><img src="../assets/images/link-hook.svg" alt="subscription"
                /></span>

                <span class="spanHover" :class="{ listDisplay: mobileMenu }">
                  Subscription</span
                >
              </li>
            </router-link>

            <!-- <li class=""  @click="membership">Membership</li> -->

            <!-- <router-link>
              <li
                class="d-flex align-items-center hover-list"
                :class="{ listPadding: mobileMenu }"
              >
                <span class="side-icon"
                  ><img
                    class="rotate"
                    src="../assets/images/white-settings.svg"
                    alt="settings"
                /></span>
                <span class="spanHover" :class="{ listDisplay: mobileMenu }"
                  >Settings</span
                >
              </li>
            </router-link> -->

            <li
              class="d-flex align-items-center hover-list"
              :class="{ listPadding: mobileMenu }"
            >
              <span class="side-icon"
                ><img
                  style="width: 1.3rem"
                  src="../assets/images/logout.svg"
                  alt=""
              /></span>
              <span
                @click.prevent="logOut()"
                class="spanHover"
                :class="{ listDisplay: mobileMenu }"
                >Logout</span
              >
            </li>
          </div>
        </div>
        <div class="outlet" :class="{ outletBody: mobileMenu }">
          <router-view></router-view>
          <subscriptionModal />

          <!-- this is also in the header component -->
          <inFoPopup />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Service } from "../store/service";
import inFoPopup from "../components/InfoPopup.vue";
import subscriptionModal from "../components/SubscriptionModal.vue";

const Api = new Service();

export default {
  name: "Navigation",
  components: {
    subscriptionModal,
    inFoPopup
  },

  data() {
    return {
      userAccount: "",
      toggleCooporative: false,
      mobileMenu: false,
      hoverView: false,
      verified: false,
      subscribed: false,
      showActive: false,
      id: "",
      user: "",
      showPopup: false,
      activeIcon: false,
      propertyNumber: 0,
      rentedpropertyNumber: 0,
      who_enter: "Landlord",
      who_you: "Landlord",
      selected: "A",
      currentPath: "",
      currentParams: "",
      invest: 0,
      who: [
        { text: "Landlord", value: "Landlord" },
        { text: "Tenant", value: "Tenant" }
      ],
      routerLinks: [
        {
          id: 1,
          to: "/dashboard/e-wallet",
          icon: "../assets/images/box.svg",
          name: "e-Wallet",
          alt: "wallet"
        },
        {
          id: 2,
          to: "/dashboard/facilities",
          icon: "../assets/images/database.svg",
          name: "Facilities",
          alt: "faci"
        }
      ]
    };
  },
  created() {
    this.$root.$refs.P = this;
    this.$root.$refs.L = this;
  },
  mounted() {
    this.id = JSON.parse(localStorage.getItem("shelta-user")).id;
    this.invest = JSON.parse(localStorage.getItem("shelta-user")).cooperative;
    // this.invest = 0;
    this.userAccount = JSON.parse(
      localStorage.getItem("shelta-user")
    ).accounttype;
    // const verified = JSON.parse(localStorage.getItem("shelta-user")).verified;
    const subscribed = JSON.parse(localStorage.getItem("shelta-user"))
      .subscribed;
    this.getUserProfile();
    this.propertyCount();
    this.rentedPropertyCount();
    this.who_you = localStorage.getItem("shelta-loger");
    // if (verified == "1") {
    //   this.verified = true;
    // } else {
    //   this.verified = false;
    // }
    if (subscribed === "YES") {
      this.subscribed = true;
    } else {
      this.subscribed = false;
    }
    this.currentPath = this.$route.path;
    this.currentParams = this.$route.params;
    setTimeout(() => {
      if (!localStorage.getItem("popup")) {
        this.infoPopup();
      }
    }, 60000);
  },
  methods: {
    // This is also in the header component
    infoPopup() {
      this.$root.$refs.F.openInfo();
    },
    toggleCoop() {
      const viewportWidth =
        window.innerWidth || document.documentElement.clientWidth;
      // if (viewportWidth <= 360 || this.mobileMenu === false) {
      //   // this.mobileMenu = !this.mobileMenu;
      //   this.toggleCooporative = false;
      // }
      if (this.mobileMenu === false || viewportWidth >= 360) {
        this.toggleCooporative = !this.toggleCooporative;
      } else {
        // this.mobileMenu = true;
        this.investRouteTo();
      }
      const invest = this.$refs.coop;
      if (this.toggleCooporative === true) {
        invest.style.margin = "0px 0px 0px 0px";
      } else {
        invest.style.margin = "0px 0px 1rem 0px";
      }
    },
    dashboardNavigation() {
      this.$router.push({
        path: `/dashboard/investment-dashboard`
      });
    },
    moveToInvestmentDashboard() {
      this.$router.push({
        path: `/dashboard/investment-dashboard`
      });
    },
    investRouteTo(route) {
      // console.log("Invest type", this.invest);
      if (this.invest == "0") {
        this.$router.push({
          path: `/dashboard/joininvestment`
        });
      } else if (this.invest == "1") {
        this.$router.push({
          path: `/dashboard/investment-dashboard`
        });
      }
    },
    membership() {
      if (this.subscribed === "NO" || this.subscribed === false) {
        this.$root.$refs.D.openSubModal();
      } else {
        return this.$store.commit("setApiFailed", "You are already subscribed");
      }
    },
    moveToSubscription() {
      this.$router.push({
        path: `/dashboard/subscription`
      });
    },
    logger: function() {
      if (this.who_you === "Landlord") {
        localStorage.setItem("shelta-loger", "Landlord");
        this.who_you = localStorage.getItem("shelta-loger");
        this.$router.push("/dashboard/landlord").catch(() => {});
      } else if (this.who_you === "Tenant") {
        localStorage.setItem("shelta-loger", "Tenant");
        this.who_you = localStorage.getItem("shelta-loger");
        this.$router.push("/dashboard/tenant").catch(() => {});
      }
    },
    propertyCount: async function() {
      try {
        await Api.getRequest(`countlanlordproperties/${this.id}`)
          .then(res => {
            if (res.data.success) {
              this.propertyNumber = res.data.count;
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    rentedPropertyCount: async function() {
      try {
        await Api.getRequest(`counttenantrentedproperties/${this.id}`)
          .then(res => {
            // console.log("Rented count", res);
            if (res.data.success) {
              this.rentedpropertyNumber = res.data.count;
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    onClose() {
      this.showPopup = false;
    },
    mouseOver: function() {
      this.activeIcon = true;
      // console.log("Action icon", this.activeIcon);
    },
    mouseOut: function() {
      this.activeIcon = false;
      // console.log("Action icon", this.activeIcon);
    },
    mouseOver2: function() {
      this.activeIcon = true;
      // console.log("Action icon", this.activeIcon);
    },
    mouseOut2: function() {
      this.activeIcon = false;
      // console.log("Action icon", this.activeIcon);
    },

    logOut: function() {
      if (localStorage.getItem("popup")) {
        window.localStorage.clear();
        localStorage.setItem("popup", "NO");
      } else {
        window.localStorage.clear();
      }
      window.cookie =
        "shelta-c" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      this.$cookies.remove("shelta-c");
      location.reload();
      this.$router.push("/login");
    },
    backtohome: function() {
      this.$router.push("/shelta");
    },
    getUserProfile: function() {
      Api.getRequest(`getuserbyid/${this.id}`)
        .then(res => {
          // console.log("Response data", res);
          if (res.status == 401) {
            window.localStorage.clear();
            return (window.location.href = "https://myshelta.com/login");
          } else {
            this.user = res.data.user;
            localStorage.setItem("shelta-user", JSON.stringify(res.data.user));
          }
        })
        .catch(err => {
          console.log("Error check", err.message);
          if (err.message == "Request failed with status code 401") {
            window.localStorage.clear();
            return (window.location.href = "https://myshelta.com/login");
          }
          // if (err.)
        });
    },
    toggleMenu2(data) {
      // console.log("mobile menu", this.mobileMenu)
      if (data != "coop") {
        this.toggleCooporative = false;
      }
      this.getUrl();
      const viewportWidth =
        window.innerWidth || document.documentElement.clientWidth;
      if (viewportWidth <= 360) {
        this.mobileMenu = !this.mobileMenu;
        if (this.mobileMenu === true) {
          const transform = document.getElementById("moveOutSlowly");
          transform.classList.add("motionIn");
        }
      }
    },
    toggleMenu() {
      this.mobileMenu = !this.mobileMenu;
      if (this.mobileMenu === true) {
        const transform = document.getElementById("moveOutSlowly");
        transform.classList.add("motionIn");
      } else {
        this.mobileMenu === false;
      }
    },
    getUrl() {
      this.currentPath = this.$route.path;
    },
    toggleMenuOut() {
      this.mobileMenu = false;
      const transform = document.getElementById("moveOutSlowly");
      transform.classList.add("addAnimationOnOut");
    },
    dropdownMoveTo(check) {
      if (check === "1") {
        this.$router.push({ path: "/dashboard/profile" });
      } else if (check === "2") {
        this.$router.push({ path: "/dashboard/viewbio" });
      } else if (check === "3") {
      } else if (check === "4") {
        this.$router.push({ path: "/addproperty" });
      } else if (check === "") {
        this.logOut();
      } else {
        this.logOut();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;

$size: 45px;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}
// Gotham: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");

@mixin navSlide($property) {
  transition-property: $property;
  transition-duration: 800ms;
}

/* The container must be positioned relative: */
.custom-select {
  position: relative;
  font-family: Arial;
}

.select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.rotate {
  animation: 1.7s linear infinite rotate;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.whatsAppIcon {
  padding: 0.8rem !important;
  img {
    cursor: pointer;
  }
}

.search {
  border: 1px solid rgba(196, 196, 196, 0.5);
  box-sizing: border-box;
  border-radius: 5px;
  height: calc(1.2em + 0.75rem + 1px) !important;
  padding: 0.1rem 0.7rem !important;
  color: rgba(196, 196, 196, 0.5);
  margin-left: 6rem;
  width: 311px;
  position: absolute;
  left: 15rem;
  margin-left: 5%;
}
.user button {
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
}

.top_bar {
  // padding: 0rem 5%;
  position: relative;
  position: fixed;
  top: 0;
  z-index: 1;
  left: 14rem;
  @include navSlide(left);
  right: 0;
  border-bottom: 1px solid #a2b0e3;
  height: 3.46rem;
}
.icon_links {
  padding: 2rem 0 0rem 0 !important;
}
.links {
  height: 84.7vh;
  overflow-y: auto;
  // padding: 0 0.5rem;
  padding: 2rem 0.5rem 0rem 0.5rem;
  &::-webkit-scrollbar {
    width: 0.3em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &link::-webkit-scrollbar-thumb {
    background-color: rgba(196, 196, 196, 0.53);
    border-radius: 30px;
    outline: 1px solid none;
  }
}
.landLordTenant-holder {
  width: 50%;
}
.landLordTenant-toggle {
  // padding: 1rem;
  background-color: $primary;
}
.landlordTenant-select {
  padding: 0.375rem 4.75rem 0.375rem 1.2rem !important;
  color: $secondary !important;
  background-color: transparent !important;
  font-family: Gotham !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  border: 1px solid $primary !important;
  height: calc(2.5em + 0.75rem + 2px) !important;
  box-shadow: none;
  // line-height: 15px;
}
.landlordTenant-select > option {
  color: grey !important;
}

.notificationHolder {
  position: relative;
  display: inline-block;
  text-decoration: none;
  border-radius: 2px;
  padding: 15px 26px;
}
.notificationCount {
  position: absolute;
  top: 7px;
  right: 6px;
  padding: 1px 5px;
  border-radius: 50%;
  background: #0033ea;
  color: #ffffff;
  font-size: 10px;
  font-size: Gotham;
}

// .notification-msg {
//   position: absolute;
//   right: -2rem;
//   height: 242px;
//   width: 37rem;
//   background: $secondary;
//   top: 3rem;
//   box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
//   border-radius: 10px;
// }
.notification-msg > p {
  background: $primary;
  color: white;
  height: 2.6rem;
  line-height: 40px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.notification-msg::after {
  content: "";
  border-width: 10px;
  margin-left: -5px;
  border-color: transparent transparent $primary transparent;
  border-style: solid;
  position: absolute;
  bottom: 100%;
  left: 89%;
}
.notifications-text {
  border-bottom: 1px solid $black;
}

.notifications-text > p:nth-child(2) {
  font-size: 12px;
  justify-content: left;
  margin-bottom: 0;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  // line-height: 11px;
  text-align: start;
  align-items: center;
  color: $black;
}
.notifications-text p:first-child {
  display: flex;
  margin: 0;
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  // line-height: 12px;
  text-align: start;
  // display: flex;
  align-items: center;
  color: $black;
}
.notification-body::before {
  content: "S";
  color: $secondary;
  background: $primary;
  width: 3rem;
  height: 3rem;
  border-radius: 100px;
  margin-right: 0.8rem;
  font-size: 25px;
  padding-top: 0.4rem;
}

.notification-body > p:first-child {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 11px;
  display: flex;
  align-items: center;
  color: $black;
}
.notification-body {
  display: flex;
  padding: 0 0.8rem 1rem 0.8rem;
  cursor: pointer;
  // justify-content: space-around;
}
.notifications-text:hover {
  background: #efefef;
}
.toggle-btn {
  img {
    cursor: pointer;
  }
}

// Active link begins
.dashboardActive,
.hover-list:hover {
  background: rgba(255, 255, 255, 0.22);
  border-radius: 25px;
}
.dashboardActive {
  background: rgba(255, 255, 255, 0.22);
  border-radius: 25px;
}
// Active links ends

.verified {
  padding: 1rem;
  font-family: "Gotham";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  color: #3ec250;
  display: flex;
  align-items: center;
  span {
    padding-left: 3px;
  }
}
.notverified {
  padding: 1rem;
  font-family: "Gotham";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  color: #ea0000;
  display: flex;
  align-items: center;
  span {
    padding-left: 3px;
  }
}

.notsubscribe {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #ea0000;
  padding: 5px;
  border-radius: 5px;
  // margin-right: 10px;
  margin-right: 13px;
  cursor: pointer;
  span {
    color: #ea0000;
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    margin-right: 5px;
  }
  img {
    width: 16px;
    cursor: pointer;
  }
}
.icon_sm:nth-child(2) {
  padding: 1rem;
  border-left: 0.5px solid $tatiary;
  border-right: 0.5px solid $tatiary;
}
.icon_sm:nth-child(3) {
  padding: 1rem;
  border-left: 0px solid $tatiary;
  border-right: 0.5px solid $tatiary;
}

.avatar {
  border: 1px solid $tatiary;
  border-radius: 50px;
  height: 1.5rem;
  width: 1.5rem;
}

.avatar-span {
  // padding: 1rem;
  padding: 1rem 0.5rem 1rem 0.6rem;
}

.underline {
  border-bottom: 1px solid $secondary;
  margin-bottom: 0;
  // padding-top: 0.5rem;
  padding-top: 0.47rem;
}
.btn-group > button:focus {
  outline: none;
}
.drop-option {
  padding: 1rem 1.5rem !important;
  color: $secondary;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  display: flex;
  align-items: center;
  box-shadow: none;
  outline: 0;
}
.drop-option:hover {
  background-color: $secondary;
  color: #000000;
}
.nav-dropdown {
  background-color: $primary;
  padding: 0 !important;
}
.side-icon {
  padding-right: 1rem;
}
.top-dropdown {
  padding-right: 1rem;
}
.top-dropdown > img {
  width: 1.2rem;
}

.main {
  min-height: 100vh;
  padding: 3.4rem 0% 2rem 0%;
  .sidebar {
    position: fixed;
    width: 14rem;
    // top: 6rem;
    top: 0;
    overflow: hidden;
    @include navSlide(width);
    overflow-x: hidden;
    padding: 0.6rem 0rem 0 0;
    bottom: 0;
    background: linear-gradient(181.56deg, #0033ea 60.26%, #014561 102.5%);
    // background-color: $primary;
    &::-webkit-scrollbar {
      width: 0.3em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(196, 196, 196, 0.53);
      border-radius: 30px;
      outline: 1px solid none;
    }
    li {
      list-style: none;
      margin-bottom: 1rem;
      padding: 0.5rem 0;
      cursor: pointer;
      padding-left: 1rem;
      color: $secondary;
      font-family: Gotham !important;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      box-shadow: none;
      outline: 0;
      // line-height: 17px;
      // &:hover {
      //   border-left: 1px solid #034110;
      //   background: #fff !important;
      //   color: $primary !important;
      //   padding-left: 1rem;
      //   border-radius: 25px;
      //   // margin-left: 1rem;
      // }
    }
    .menudrop {
      // background: rgba(255, 255, 255, 0.22);
      margin: 0 23px 0px 34px;
      margin: 0 23px 0px 58px;
      padding: 10px;
      color: $secondary;
      animation: slideinout 1s ease-in-out;
      text-align: left;
      h6 {
        cursor: pointer;
        font-family: Gotham;
        font-size: 14px;
      }
    }
    @keyframes slideinout {
      0% {
        transform: translateY(100px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
    .collapse.show .items {
      padding-left: 2.7rem;
    }
    .collapse li {
      margin-top: 0.7rem;
      margin-bottom: 0.5rem;
    }
  }
  .outlet {
    margin-left: 14rem;
    @include navSlide(margin-left);
    background-color: #e5e5e5;
    // background-color: #F9F9F9;
    // overflow-x: auto;
  }
}

.router-link {
  text-decoration: none;
  color: $secondary;
}
.router-link:hover {
  background: #fff;
  color: $primary;
  padding-left: 1rem;
  border-radius: 25px;
}

.motionIn {
  width: 14rem !important;
  // transition: 1s ease-in-out;
  // transform: translateX(0%) !important;
}

.motionOut {
  width: 4rem !important;
  // transition: 1s ease-in-out;
  // transform: translateX(0%) !important;
}

.brandHolder {
  padding-left: 0.7rem !important;
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
}
.logoSize {
  // width: 2.5rem !important;
  width: 2.5rem !important;
  transition: 1s ease-in-out;
}
.hamburgerDisplay {
  display: none;
  cursor: pointer;
}
.hamburger {
  cursor: pointer;
}

.listPadding {
  padding-left: 1rem !important;
}
.listDisplay {
  display: none !important;
}
.propertyCount {
  margin-left: 0.5rem;
  background: $secondary;
  border-radius: 50px;
  color: $primary;
  // padding: 0.25rem 0.6rem;
  font-family: Gotham;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  // position: relative;
  // left: 49px;
}

// .router-link-active {
//   background: rgba(255, 255, 255, 0.22);
//   border-radius: 25px;
// }
.dashboard_active {
  background: rgba(255, 255, 255, 0.22) !important;
  border-radius: 25px;
}
// .spanHover:hover {
//     background: $secondary;
//     color: $primary;
//     border-radius: 50px;
//     padding: 0.5rem 1rem;
//     font-size: 10px;
//   }

// .spanHover {
//     background: $secondary;
//     color: $primary;
//     border-radius: 50px;
//     padding: 0.5rem 1rem;
//     font-size: 10px;
//   }
.outletBody {
  margin-left: 4rem !important;
  @include navSlide(margin-left);
  // transition: 1s ease-in-out;
}
.topBarResize {
  left: 4rem !important;
  @include navSlide(left);
  // transition: 1s ease-in-out;
}
.addAnimationOnOut {
  transition: 1s ease-in-out !important;
}

.mobile {
  display: block;
}
.brand {
  justify-content: space-between;
  padding-left: 1.4rem;
  // padding-bottom: 3rem;
}
.brand > img:first-child {
  width: 4rem;
  @include navSlide(width);
}
.brand > img:nth-child(2) {
  padding-right: 0.5rem;
  @include navSlide(padding-right);
}

// New intake
.bg_app_primary {
  background: $secondary !important;
}
.text_app_primary {
  color: color(primary) !important;
}
.bg_app_tertiary {
  background: color(tertiary) !important;
}
.bg_app_alttertiary {
  background: color(alt_tertiary) !important;
}
.bg_app_outlet {
  background-color: #e5e5e5 !important;
  height: 100vh;
}
.text_app_tertiary {
  color: color(tertiary) !important;
}
.text_app_dark {
  color: color(text_black) !important;
}
.text_app_black {
  color: color(deep_black) !important;
}
.text_app_grey {
  color: color(_grey) !important;
}
.text_app_inactive {
  color: color(_inactive);
}
.text_app_green {
  color: color(_green);
}
.bg_app_light {
  background: color(light) !important;
}
.bg_app_grey {
  background: color(_grey) !important;
}
.bg_app_green {
  background: color(_green) !important;
}
.bg_app_inactive {
  background: color(_inactive) !important;
}
.bg_app_altsecondary {
  background: color(alt_secondary) !important;
}
.bg_app_secondary {
  background: color(secondary) !important;
}

.min_page {
  min-height: 100vh;
}
.position_absolute {
  position: absolute !important;
}
.flex_x_betwwen {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex_x_end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.d_grid {
  display: grid;
}
.flex_center {
  display: flex;
  align-items: center;
}

// New entry
.router_active {
  font-weight: 600;
  color: color(tertiary) !important;
}
.nav-tabs {
  padding: 0.12rem 0.13rem !important;
  border-bottom: none !important;
}
.nav-tabs .nav-item {
  margin-right: 2rem;
  border: none !important;
  padding: 0.3rem 0.5rem;
}
.nav-tabs .nav-link {
  display: block;
  padding: 0.5rem 0;
}

.nav-tabs .nav-link:nth-child(1) {
  padding: 0.2rem 0;
}
.nav-tabs a {
  color: #505050;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: color(primary);
  background: color(light);
  border-radius: 4px;
  padding: 0.3rem 2rem;
  border: none !important;
  position: relative;
}

.nav-tabs .nav-link {
  border: none !important;
  &:hover,
  &:active,
  &:focus {
    border: none !important;
  }
}
.bg_none {
  background: none !important;
}

.desktopBurger {
  display: none;
}
.top_bar {
  z-index: 2;
}

@media screen and (min-device-width: 320px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 1) {
  .landlordTenant-select {
    padding: 0.375rem 0rem 0.375rem 0rem !important;
    // padding: 0.375rem 1.09rem 0.375rem 0.2rem !important;
  }
  .propertyCount {
    display: flex !important;
  }
}
@media screen and (min-device-width: 360px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 1) {
  .landlordTenant-select {
    padding: 0.375rem 0rem 0.375rem 0rem !important;
    // padding: 0.375rem 2.75rem 0.375rem 1.2rem !important;
  }
  .propertyCount {
    display: flex !important;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .notification-msg {
    position: absolute;
    right: -2.9rem;
    // height: 242px;
    width: 37rem;
    background: $secondary;
    top: 3rem;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    top: 1rem;
    // overflow-y: auto;
  }
  .noti-body {
    height: 242px;
    overflow-y: auto;
  }
  .notification-msg::after {
    content: "";
    border-width: 10px;
    margin-left: -5px;
    border-color: transparent transparent $primary transparent;
    border-style: solid;
    position: absolute;
    bottom: 100%;
    left: 89%;
  }
}

@media screen and (max-width: 968px) {
  // .hamburger {
  //   display: none;
  // }
  .bugermobile {
    display: none;
  }
  .main {
    .sidebar {
      position: fixed;
      z-index: 999;
      transform: translateX(-150%);
      // background: rgba(0, 0, 0, 0.96) !important;
      transition: transform 0.5s ease-in-out;
      padding: 3rem 7%;
      width: 100%;
      top: 0;
      left: 0;
      right: 15%;
      bottom: 0;
    }
    li {
      color: #fff !important;
    }
    .outlet {
      margin-left: 0;
      // padding-left: 0;
    }
  }
  .search {
    left: 8rem;
  }
  .mobile {
    display: none !important;
  }
  .motionOut {
    width: 14rem !important;
    transition: 1s ease-in-out;
    transform: translateX(0%) !important;
  }
  .listDisplay {
    display: inline-flex !important;
  }
  .desktop {
    display: none;
  }
  .top_bar {
    left: 0 !important;
  }
}

// @media screen and (min-width: 968px) {
//   .top_bar {
//     left: 0 !important;
//   }
// }

@media screen and (max-width: 768px) {
  .top_bar {
    left: 0rem !important;
  }
  .notification-msg {
    right: -2.9rem;
    width: 37rem;
    background: #ffffff;
    top: 3rem;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    top: 1rem;
    position: absolute;
  }
}

@media screen and (max-width: 599px) {
  .top_bar {
    left: 0rem;
  }
  .landLordTenant-holder {
    justify-content: stretch !important;
  }
  .landLordTenant-holder > div {
    padding-left: 0 !important;
  }
  .landlordTenant-select {
    padding: 0.375rem 2.75rem 0.375rem 1.2rem;
  }
  .main {
    .sidebar {
      right: 0;
      padding: 0rem;
    }
    .outlet {
      padding-left: 0;
      margin-left: 0 !important;
    }
  }
  .search {
    display: none !important;
  }
  .desktopBurger {
    display: block;
    outline: none;
  }
  .notification-msg {
    position: absolute;
    right: -7rem;
    // height: 183px;
    width: 21rem;
    background: $secondary;
    top: 1.5rem;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
  .notification-msg::after {
    left: 61%;
    width: 1rem;
  }
  .noti-body {
    height: 242px;
    overflow-y: auto;
  }
  .brandHolder {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important;
  }
}
</style>
